import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor() { }

    hasRole(itemTitle: string): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        const userRole = user?.role;

        if (!userRole) {
            return false;
        }

        switch (userRole) {
            case 'admin':
                return true;
            case 'store_manager':
                if(itemTitle === 'Users') {
                    return false;
                } else if(itemTitle === 'Proposals') {
                    return false;
                } else if(itemTitle === 'Email Notifications') {
                    return false;
                } else {
                    return true;
                }
            case 'prepress':
                return ['Awaiting Prepress'].includes(itemTitle);;
            case 'communication_manager':
                return ['Communications'].includes(itemTitle);
            case 'installer':
                return ['Installer Jobs', 'Removal Jobs'].includes(itemTitle);
            case 'client_admin':
                return ['Client Map', 'Client Users', 'User Artwork', 'User Agreement'].includes(itemTitle);
            case 'client_user':
                return ['Client Map', 'User Artwork'].includes(itemTitle);
            case 'client_designer':
                return ['User Artwork'].includes(itemTitle);
            case 'accounting_manager':
                return ['AccountPayables', 'Overview', 'Store/Vendor', 'sheet'].includes(itemTitle);
            default:
                return false;
        }
    }
}
